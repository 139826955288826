/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #5a656e;
}

a {
  color: #5a656e;
  
}

a:hover {
  color: #00d4a6;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  right: 15px;
  bottom: 15px;
  background: none;
  color: #00d4a6;
  transition: display 0.5s ease-in-out;
  z-index: 99999;
}

.back-to-top i {
  font-size: 24px;
  position: absolute;
  top: 7px;
  left: 8px;
}

.back-to-top:hover {
  color: #fff;
  background: #929292a4;
  transition: background 0.2s ease-in-out;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 10px 0;
  background: #00d4a6;
  
}

#header .logo h1 {
  font-size: 26px;
  margin: 0;
  padding: 6px 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header .logo h1 a, #header .logo h1 a:hover {
  color: #2c4964;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #ffffff;
  padding: 13px;
  transition: 0.3s;
  font-size: 16px;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  color: #949494;
  text-decoration: none;
}


.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}



.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #2c4964;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  color: #00d4a6;
  ;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }

  #header .img-fluid {
    max-width: 80%;
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 12px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #ffffff;
}

.mobile-nav {
  position: fixed;
  top: 52px;
  width: 100%;
  height: auto;
  z-index: 9999;
  overflow-y: auto;
  transition: ease-in-out 0.2s;
  background: #00d4a685;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #ffffff;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  font-weight: bolder;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}



.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: #3b615971;
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background-color:#00d4a6;
  background-size: cover;
  padding: 0 15px;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

#hero h2 {
  color: #eee;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}

#hero .btn-get-started {
  font-size: 64px;
  transition: 0.5s;
  color: #fff;
  height: 100px;
  padding-top: 90px;
}

#hero .btn-get-started:hover {
  padding-top: 100px;
  color: #c9c9c9;
}




@media (min-width: 768px) {
  #hero {
    background-attachment: fixed;  
  }
  #hero .img-fluid {
    max-width: 30%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 38px;
    line-height: 36px;
  }
  
  #hero .img-fluid {
    max-width: 50%;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/


.section-bg {
  background: linear-gradient(180deg, #f2f6f9 0%, #fff 100%);
}

.section-title {
  padding-bottom: 0px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
  color: #345676;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #00d4a6;
  ;
  bottom: 0;
  left: 0;
}



/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/


.about .content {
  padding: 10px 0;
}

.about .content h3 {
  font-weight: 500;
  font-size: 44px;
  color: #00d4a6;
}

.about .content p {
  margin-bottom: 0;
}

.about .image {
  background: url("../img/me.png") center center no-repeat;
  background-size: cover;
  min-height: 400px;
}



@media (max-width: 991px) {
  .about .image {
    text-align: center;
  }
  .about .image img {
    max-width: 80%;
  }
}

@media (max-width: 667px) {
  .about .image img {
    max-width: 100%;
  }
}




/*--------------------------------------------------------------
# Projet
--------------------------------------------------------------*/

#projet {
  padding: 50px;
  background-color: #ffffff;
}

/*--------------------------------------------------------------
# Actuality
--------------------------------------------------------------*/

#actuality {
  padding: 50px;
  background-color: #ffffff;
}

/*--------------------------------------------------------------
# Stages Ciné-Musique
--------------------------------------------------------------*/

.dlbulletin:hover{
  filter: drop-shadow(1px 1px 1px #00d4a6);
}


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
#contact {
  padding: 30px 0;
}

.contact .contact-about h3 {
  font-size: 36px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #00d4a6;
}

.contact .contact-about p {
  font-size: 14px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  color: #3c6387;
}

.contact .social-links {
  padding-bottom: 20px;
}

.contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(255, 255, 255, 0);
  color: #00d4a6;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid #00d4a6;
}

.contact .social-links a:hover {
  background: #c0c0c033;
  color: rgb(71, 71, 71);
}

.contact .info { 
  color: #5a656e;
  padding-left: 25%;
}

.contact .info i {
  font-size: 32px;
  color: #00d4a6;
  float: left;
  line-height: 1;
}

.contact .info p {
  padding: 0 0 10px 42px;
  line-height: 28px;
  font-size: 16px;
}

.contact .logopart:hover {
  filter: drop-shadow(1px 1px 1px rgb(148, 148, 148));
}


/*--------------------------------------------------------------
# Ciné-musique > Section Archive
--------------------------------------------------------------*/
.archive {
  background: #00d4a6;
}

.archive h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
  color: #ffffff;
  padding-top: 15px;
}

.archive h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #ffffff;
  ;
  bottom: 0;
  left: 0;
}
.archive p {
  color: #ffffff;
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #fff;
  padding: 24px 0;
  font-size: 14px;
  box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.12);
}

#footer .copyright {
  text-align: center;
}

#footer .credits {
  padding-top: 8px;
  text-align: center;
  font-size: 13px;
}



